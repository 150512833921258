import router from './index'
import {
	wxConfig
} from '@/api/wxApi';
import {
	getQrCode,
	scanCode,
	wxLog,
	shopAddOne,
	findUserInfo
} from '@/api'
import store from '../store/index'

import {
	setPageTitle
} from '@/utils/index'
import {
	Toast
} from 'vant'

let isEntry = 1


router.beforeEach((to, from, next) => {
	if (to.name != 'VideoPlay' && to.name != 'VideoMiddle' && to.name != 'ProductDetails') {
		const shopInfo = store.state.shopInfo
		const title = shopInfo ? shopInfo.list?.shopName : '';
		if (title) {
			setPageTitle(title);
		}
	} else {
		setPageTitle(from?.meta?.title)
	}

	if (process.env.NODE_ENV == 'development') {
		setDevData()
		next()
	} else {
		const {
			shopUuid,
			code,
			uuid
		} = to.query
		if (to.path === '/videoPlay') {
			localStorage.setItem('fromPath', from.path)
		}
		if (isEntry == 1) {
			isEntry = 2
			// 店铺增加访问次数
			shopAddOne({
				uuid: shopUuid
			})
		}
		if (shopUuid) {
			store.commit('SET_SHOP_UUID', shopUuid)
		}
		const appUser = localStorage.getItem('appUser') || ''
		if (appUser) {
			wxLog({
				uuid: appUser
			});
			// window.wx_uuid = appUser;
		}
		login(shopUuid, uuid)
		next()
	}
})

function setDevData() {
	// 测试环境1  wuIuF751591108 pNdrm1588227027 Dyvej678421037 NrbNl281347160 WpVDm1556071850 iHyrd139090151
	const shopUuid = 'KThji753062776'
	store.commit('SET_SHOP_UUID', shopUuid)
	const userInfo = {
		'openid': 'oKXld6UgJppgFGYXFrewYvgN2fuY',
		'lv': 99,
		'uuid': '16d06549787f64603e02a154b5c2ec00',
		'sex': 1,
		'nickname': 'bruce',
		'headimgurl': 'https://upload.tuomaimai.com/wechat/userIoc/ogRkT6BEacXLKGadrMV-M_TZVKNI.jpg',
		'optionDate': '2021-01-27T21:50:32',
		'block': 0, // 0 是没拉黑  1是拉黑了
	}
	store.commit('SET_OPENID', userInfo.openid)
	const uuid = '16d06549787f64603e02a154b5c2ec00'
	store.commit('SET_USER_INFO', userInfo)
	localStorage.setItem('userInfo', JSON.stringify(userInfo))
	
}

// 登陆
function login(shopUuid, uuid) {
	const params = {
		url: location.pathname,
		shopUuid,
		uuid
	}
	findUserInfo({
		wxOpenId: store.state.userInfo.openid,
		shopUuid: store.state.shopUuid
	}).then(resp => {
		if (resp.state == 100 && resp.items) {
			store.commit('SET_USER_INFO', resp.items)
			localStorage.setItem('appUser', resp.items.uuid)
			window.wx_uuid = resp.items.uuid;
		}
	})
	if (!store.state.openid) {
		getQrCode(params).then(res => {
			if (res.state == 100) {
				const {
					url
				} = res.items
				window.location.href = url
			} else {
				localStorage.clear()
				Toast(res.msg)
			}
		})
	}

}